import React from 'react'
import ContentsHeader from "./ContentsHeader";

export function CommonWrapper(Component) {

  class InterceptorComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
          <div id="wrap">
            {/*<div id="leftBar"></div>*/}
            {/*<header id="header">*/}
            {/*  <div className="header-wrap">*/}
            {/*    <h1 className="title">Header</h1>*/}
            {/*  </div>*/}
            {/*</header>*/}
            {/*<ContentsHeader props={this.props}/>*/}
            <Component {...this.props} />
          </div>
      )
    }
  }

  return InterceptorComponent

}
