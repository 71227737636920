import React, {Component} from 'react';

import {axiosInstance} from '../../common/Request';
import {CHART_INFO} from "../chart/chartConstant";


export default class SearchChartView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            queryId: null,
            countryNames: []
        }
        this.countryInfo = [
            { code: 'KR', name: '한국' },
            { code: 'JP', name: '일본' },
            { code: 'US', name: '미국' },
            { code: 'EP', name: '유럽' },
            { code: 'CN', name: '중국' },
        ]
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.countries !== nextProps.countries) {
            const countryNames = this.countryInfo.filter((info)=>{
                return nextProps.countries.indexOf(info.code) > -1;
            }).map((info)=>{
                return info.name;
            });
            this.setState({ countryNames });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.activeChartId && this.props.activeChartId !== nextProps.activeChartId) {
            return true;
        }
        if(this.props.isLoading !== nextProps.isLoading) {
            return true;
        }
        if(this.props.queryId !== nextProps.queryId) {
            return true;
        }
        return false;
    }

    render() {
        const { countryNames } = this.state;
        const { isLoading, chartData, activeChartId, queryId, company, keywords } = this.props;

        const chartInfo = activeChartId && CHART_INFO[activeChartId];

        console.log('chartInfo', chartInfo);

        const Chart = chartInfo && CHART_INFO[activeChartId].component;

        return (
            <div className="service-content">
                {
                    queryId && (
                        <div className="service-head">
                            {company? (<span><b>{company} 회사</b>가</span>): null} <b>{countryNames.join(', ')}</b>에 보유하고 있는<br/>
                            <b>{keywords.join(', ')}</b>기술을 분석한 내용입니다.
                        </div>
                    )
                }
                <div className="service-tab-content">
                    <Chart chartType={activeChartId} chartData={chartData} isLoading={isLoading} onExcelDownload={this.excelDownload.bind(this)}/>
                </div>
            </div>
        );
    }

    /**
     * 엑셀 다운로드
     * @param chartType
     * @param chartData
     */
    excelDownload(chartType, chartData) {
        axiosInstance.post('/v1/compa/excel', JSON.stringify({
            type: chartType,
            data: chartData
        }), { responseType: 'blob' })
            .then((response)=>{
                const chartName = CHART_INFO[chartType].name.replace(' ', '_');
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${chartName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}
