import React from 'react';
import ReactEcharts from "echarts-for-react";

const PatentRatingByCountry = ({ isLoading, chartData, chartType, onExcelDownload}) => {

    let data = chartData && chartData.data.map(function (item) {
        return [item[1], item[0], item[2] || '-'];
    });

    return (
        <div className="service-tab-item active">
            <div className="service-title">국가별 표준특허 평가등급</div>
            <div className="service-text">
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {
                                        position: 'top'
                                    },
                                    animation: false,
                                    grid: {
                                        height: '50%',
                                        top: '10%'
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: chartData.rate,
                                        splitArea: {
                                            show: true
                                        }
                                    },
                                    yAxis: {
                                        type: 'category',
                                        data: chartData.countries,
                                        splitArea: {
                                            show: true
                                        }
                                    },
                                    visualMap: {
                                        min: chartData.min,
                                        max: chartData.max,
                                        calculable: true,
                                        orient: 'horizontal',
                                        left: 'center',
                                        bottom: '15%'
                                    },
                                    series: [{
                                        name: '등급별 건수',
                                        type: 'heatmap',
                                        data: data,
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        }
                                    }]
                                }}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
        </div>
    );
}

export default PatentRatingByCountry;
