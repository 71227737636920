import React, {Component} from 'react';
import { axiosInstance } from '../../common/Request';
import {getSessionInfo} from "./authenticationUtil";
import {Link} from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingSearch: false
        }

        const savedId = localStorage.getItem('saved_id');
        this.savedId = savedId;
    }

    render() {
        return (
            <div>
                <div className={'loader'} style={{ display: this.state.isLoadingSearch? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>로그인 중입니다.</div>
                    </div>
                </div>
                <div className="user-area">
                    <h2 className="user-title">AI기반 기술경영 어드바이저</h2>
                    <form className="user-form">
                        <div className="user-box">

                            <div className="input-area">
                                <div className="box-input"><input ref={c=>this.userId=c} type="text" id={'id'} defaultValue={this.savedId} placeholder="아이디" onKeyDown={this.handleLoginKeyDown.bind(this)}/></div>
                                <div className="box-input"><input ref={c=>this.userPw=c} type="password" id={'password'} defaultValue={''} placeholder="비밀번호" onKeyDown={this.handleLoginKeyDown.bind(this)}/></div>
                                <div className="box-info">
                                    <div className="innr-check box-check">
                                        <input type="checkbox" name="idSave" id="idSave" value="n" title="아이디 저장" onChange={this.rememberId.bind(this)}/>
                                        <label htmlFor="idSave">아이디 기억하기</label>
                                    </div>
                                    <div className="innr_link">
                                        <Link to={'/ipservice/member/findPassword'}>비밀번호 찾기</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-area">
                                <div className="box-button">
                                    <button type="button" className="submit" name="" onClick={this.login.bind(this)}>로그인</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="user-button"><Link to={'/ipservice/member/join'}>회원가입하기</Link></div>
                </div>
            </div>
        );
    }

    /**
     * 홈화면 이동
     */
    goHome() {
        this.props.history.push('/ipservice/');
    }

    /**
     * 키이벤트
     * @param e
     */
    handleLoginKeyDown(e) {
        if(e.keyCode === 13) {
            this.login();
        }
    }

    rememberId() {
        if(document.querySelectorAll('#idSave:checked').length > 0) {
            localStorage.setItem('saved_id', this.userId.value);
        }
    }

    /**
     * 로그인
     */
    login() {
        if(!this.userId.value) {
            this.userId.focus();
            alert('아이디를 입력해주세요.');
            return false;
        }
        if(!this.userPw.value) {
            this.userPw.focus();
            alert('패스워드를 입력해주세요.');
            return false;
        }
        this.setState({ isLoadingSearch: true });
        axiosInstance.post('/v1/access/compa', JSON.stringify({ id: this.userId.value, password: this.userPw.value }))
            .then(result => {
                getSessionInfo()
                    .then(()=>{
                        this.props.history.push('/ipservice/');
                    });
            })
            .finally(()=>{
                this.setState({ isLoadingSearch: false });
            });
    }
}

export default Login;
