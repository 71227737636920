import {
    CompetitorsLandscape,
    CorporateNationalityDistribution, MajorTechnicalFieldDistribution,
    NumberOfPatentsByCountry, NumberOfPatentsPerYear,
    PatentDisputesByCountry, PatentRatingByCountry, TechnologyLevelByCompany,
    TopMajorEnterprises, TopicClassification, CompetitorsTop20
} from '../chart';

/**
 * 차트 정보
 * @type {{sixth: {component: TopicClassification, name: string}, tenth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, third: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, seventh: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, eighth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, ninth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, fifth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, fourth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, first: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, second: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}}}
 */
export const CHART_INFO = {
    'second': {
        name: '국가별 표준특허현황',
        component: NumberOfPatentsByCountry
    },
    'seventh': {
        name: '국가별 표준특허 평가등급',
        component: PatentRatingByCountry
    },
    'ninth': {
        name: '표준기구별 특허분포',
        component: PatentDisputesByCountry
    }
}
