import React, {Component} from 'react';
import SearchChartView from './SearchChartView'
import { axiosInstance } from '../../common/Request';
import {CHART_INFO} from "../chart/chartConstant";

export default class SimpleSearch extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        this.state = {
            isLoadingSearch: false,
            isLoadingChart: false,
            keywordElementList: [''],
            queryId: null,
            countries: '',
            company: '',
            activeChartId: 'second',
            chartData: null,
            keywords: null,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(JSON.stringify(this.state.keywordElementList) !== JSON.stringify(nextState.keywordElementList)) {
            return true;
        }
        if(this.state.isLoadingSearch !== nextState.isLoadingSearch) {
            return true;
        }
        if(this.state.isLoadingChart !== nextState.isLoadingChart) {
            return true;
        }
        if(this.state.queryId !== nextState.queryId) {
            return true;
        }
        if(this.state.activeChartId !== nextState.activeChartId) {
            return true;
        }
        return false;
    }

    render() {
        const { keywordElementList, isLoadingSearch, queryId, countries, company, keywords, activeChartId, chartData, isLoadingChart } = this.state;
        const chartOrder = ['second', 'ninth', 'seventh' ];

        return (
            <div className={'service-wrap'}>
                <div className="content">
                    <div className="content-search">
                        <div className={'loader'} style={{ display: isLoadingSearch? 'block': 'none' }}>
                            <div className={'loader-img'}>
                                <img src={'/images/common/loadingBar.gif'} />
                                <div className={'loading-text'}>검색 중입니다.</div>
                            </div>
                        </div>
                        <h2 style={{ top: 0, left: 0, padding: '20px 30px', color: '#ffffff', position: 'absolute', backgroundColor: 'rgb(38 50 56)', width: '100%' }}>정보원 성장지원사업</h2>
                        <ul className="service-tab-menu">
                            {
                                chartOrder.map((chartId, idx)=>{
                                    return <li key={`chartButton${idx}`} className={activeChartId === chartId? 'active': ''}><a id={activeChartId} onClick={this.handleClickChart.bind(this, chartId, queryId, countries, company)}>{CHART_INFO[chartId].name}</a></li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="content-body">
                        <div className={'search-wrapper'}>
                            <div className="search-area half">
                                <h4 className="search-title" style={{ position: 'relative' }}>
                                    국가를 선택하세요
                                    <span className="search-title error-msg" ref={c=>this.errorRequireCountry=c} style={{ display: 'none', position: 'absolute', top: 0,left: 140 }}><em className={'fa fa-exclamation-triangle'}/> 국가를 하나 이상 선택해주세요.</span>
                                </h4>
                                <ul className="check-list" style={{ paddingTop: 5 }}>
                                    <li>
                                        <input type="checkbox" id="countries1" name="countries" value={'KR'}/>
                                        <label htmlFor="countries1">한국</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" id="countries2" name="countries" value={'JP'}/>
                                        <label htmlFor="countries2">일본</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" id="countries3" name="countries" value={'US'}/>
                                        <label htmlFor="countries3">미국</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" id="countries4" name="countries" value={'EP'}/>
                                        <label htmlFor="countries4">유럽</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" id="countries5" name="countries" value={'CN'}/>
                                        <label htmlFor="countries5">중국</label>
                                    </li>
                                </ul>
                            </div>
                            <div className="search-area half" style={{ display: 'none' }}>
                                <h4 className="search-title" style={{ position: 'relative' }}>
                                    기술분야를 선택하세요
                                    <span className="search-title error-msg" ref={c=>this.errorRequireTechIpc=c} style={{ display: 'none', position: 'absolute', top: 0,left: 140 }}><em className={'fa fa-exclamation-triangle'}/> 기술분야를 하나 이상 선택해주세요.</span>
                                </h4>
                                <ul className="checked_tabs">
                                    <li>
                                        <input type="checkbox" name="techIpcs" id="techIpcs1" value={'EL'}/>
                                        <label htmlFor="techIpcs1">전기전자</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="techIpcs" id="techIpcs2" value={'ME'}/>
                                        <label htmlFor="techIpcs2">기계</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="techIpcs" id="techIpcs3" value={'CH'}/>
                                        <label htmlFor="techIpcs3">화학·바이오</label>
                                    </li>
                                </ul>
                            </div>
                            <div className="search-area" style={{ display: 'inline-block', width: '80%', position: 'relative' }}>
                                <h4 className="search-title">표준특허 검색항목</h4>
                                <h4 className="search-title error-msg" ref={c=>this.errorRequireText=c} style={{ display: 'none', position: 'absolute', top: 0,left: 250 }}><em className={'fa fa-exclamation-triangle'}/> 기업명 또는 제품/기술명을 입력해주세요.</h4>
                                <dl className="search-data search-data-name">
                                    <dt style={{ position: 'relative' }}>
                                        기업명
                                        <h4 className="search-title error-msg" ref={c=>this.errorCompanyText=c} style={{ display: 'none', position: 'absolute', top: 0,left: 100 }}><em className={'fa fa-exclamation-triangle'}/> 기업명을 입력해주세요.</h4>
                                        <h4 className="search-title error-msg" ref={c=>this.errorCompanyTextLength=c} style={{ display: 'none', position: 'absolute', top: 0,left: 100 }}><em className={'fa fa-exclamation-triangle'}/> 기업명은 두글자 이상 입력해주세요.</h4>
                                    </dt>
                                    <dd>
                                        <div className="box-input">
                                            <input ref={c=>this.companyName=c} type="text" id={'company'} placeholder=""/>
                                        </div>
                                    </dd>
                                </dl>
                                <dl className="search-data search-data-keyword">
                                    <dt style={{ position: 'relative' }}>
                                        기술/표준기구명
                                        <span className="search-title error-msg" ref={c=>this.errorKeywordLength=c} style={{ display: 'none', position: 'absolute', top: 0,left: 100 }}><em className={'fa fa-exclamation-triangle'}/> 제품/기술명은 두글자 이상 입력해주세요.</span>
                                    </dt>
                                    {
                                        keywordElementList.map((keyword, idx)=>{
                                            return (
                                                <dd key={`keywordElementList_${idx}`} className="box-keyword">
                                                    <div className="box-input">
                                                        <input type="text" name={'keywords'} placeholder="키워드"/>
                                                        {
                                                            keywordElementList.length === (idx+1)?
                                                                <button className={'search-data-btn search-data-add'} type="button" onClick={this.addKeyword.bind(this)}/>
                                                                : <button className={'search-data-btn search-data-del'} type="button" onClick={this.removeKeyword.bind(this, idx)}/>
                                                        }
                                                    </div>
                                                </dd>
                                            )
                                        })
                                    }
                                </dl>
                            </div>
                            <div className="search-button" style={{ display: 'inline-block', width: '20%', paddingLeft: '5%', paddingTop: '5%' }}>
                                {
                                    this.session && this.session.remain <= 0
                                        ? <button type="button" style={{ backgroundColor: '#999999' }}>결과보기</button>
                                        : <button type="button" onClick={this.search.bind(this)}>결과보기</button>
                                }
                            </div>
                        </div>
                        {
                            this.session && this.session.remain <= 0
                                ? (
                                    <div className="content-body">
                                        <div className="service-end">
                                            <div className="service-end-title">
                                                무료체험이 종료되었습니다.<br/>정식사용을 원하시는 분은 아래로 연락주세요.
                                            </div>
                                            <div className="service-end-contact">
                                                E. crm@wert.co.kr<br/>T. 02-521-0110
                                            </div>
                                            <div className="service-end-text">다양한 특허검색과 분석이 필요하시면 키워트를 체험해보세요.</div>
                                            <div className="service-end-btn">
                                                <a href="https://www.keywert.com">키워트 바로가기</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <SearchChartView queryId={queryId} countries={countries} company={company} keywords={keywords} activeChartId={activeChartId} chartData={chartData} isLoading={isLoadingChart}/>
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 홈화면 이동
     */
    goHome() {
        this.props.history.push('/ipservice/');
    }

    /**
     * 검색
     */
    search() {
        const params = this.searchValidation();
        if(!params) return;
        this.setState({ isLoadingSearch: true });
        this.clearSearchData();
        axiosInstance.post('/v1/compa/search', JSON.stringify({
            countries: params.countries,
            techIpcs: params.techIpcs,
            keywords: params.keywords,
            company: this.companyName.value
        }))
            .then((result)=>{
                console.log('result :', result);
                this.handleClickChart(this.state.activeChartId, result.data.queryId, params.countries.join(','), this.companyName.value);
                this.setState({
                    queryId: result.data.queryId,
                    countries: params.countries.join(','),
                    company: this.companyName.value,
                    chartData: null,
                    keywords: params.keywords
                });
            })
            .catch((err)=>{
                this.clearSearchData();
            }).finally(()=>{
                this.setState({ isLoadingSearch: false });
            })
    }

    /**
     * 차트 선택 및 통계데이터 조회
     * @param chartId
     */
    handleClickChart(chartId, queryId, countries, company) {
        console.log('chartId, queryId, countries, company : ', chartId, queryId, countries, company);
        if(chartId === this.state.activeChartId && queryId === this.state.queryId) return;
        if(!queryId) {
            this.setState({
                queryId: null,
                activeChartId: chartId,
                chartData: null
            });
            return false;
        }

        this.setState({ isLoadingChart: true, chartData: null });

        axiosInstance.get('/v1/compa/graph', {
            params: {
                queryId,
                countries,
                type: chartId,
                company
            }
        })
            .then((result)=>{
                console.log('result : ', result);
                this.setState({
                    queryId,
                    activeChartId: chartId,
                    chartData: result.data && result.data.data
                });
            })
            .catch((err)=>{
                console.error('err : ', err);
                this.setState({
                    queryId: null,
                    activeChartId: chartId,
                    chartData: null
                });
            })
            .finally(()=>{
                this.setState({ isLoadingChart: false });
            });
    }

    /**
     * 검색관련 데이터 초기화
     */
    clearSearchData() {
        this.setState({
            queryId: null,
            countries: '',
            company: '',
            keywords: null
        });
    }

    /**
     * 검색 유효성 체크
     * @return {{techIpcs: *[], keywords: *[], countries: *[]}|null}
     */
    searchValidation() {
        let result = null;
        let invalidKeywordCnt = 0;
        const countries = [...document.querySelectorAll('[name=countries]:checked')].map((country)=>{
            return country.value;
        });
        const techIpcs = ['EL', 'ME', 'CH'];
        // const techIpcs = [...document.querySelectorAll('[name=techIpcs]:checked')].map((techIpc)=>{
        //     return techIpc.value;
        // });
        const keywords = [...document.querySelectorAll('[name=keywords]')].filter((keyword)=>{
            return (keyword.value !=='');
        }).map((keyword)=>{
            return keyword.value;
        });

        for(let i=0; i < keywords.length; i++) {
            if(keywords[i].length < 2) {
                invalidKeywordCnt++;
            }
        }

        if(countries.length === 0 || techIpcs.length === 0
            || (!this.companyName.value && keywords.length === 0)
            || invalidKeywordCnt > 0
            || (this.companyName.value && this.companyName.value.length < 2)) {

            this.errorRequireCountry.style.display = (countries.length === 0)? 'block': 'none'; // 국가 선택
            this.errorRequireTechIpc.style.display = (techIpcs.length === 0)? 'block': 'none'; // 기술 선택
            this.errorRequireText.style.display = (!this.companyName.value && keywords.length === 0)? 'block': 'none'; // 기술키워드 입력
            this.errorCompanyText.style.display = (!this.companyName.value && keywords.length === 0)? 'block': 'none'; // 기업명 입력
            this.errorCompanyTextLength.style.display = (this.companyName.value && this.companyName.value.length < 2)? 'block': 'none'; // 기업명 입력길이
            this.errorKeywordLength.style.display = invalidKeywordCnt > 0? 'block': 'none'; // 기술키워드 입력길이

        } else {
            this.errorRequireCountry.style.display = 'none'; // 국가 선택
            this.errorRequireTechIpc.style.display = 'none'; // 기술 선택
            this.errorRequireText.style.display = 'none'; // 기술키워드 입력
            this.errorCompanyText.style.display = 'none'; // 기업명 입력
            this.errorCompanyTextLength.style.display = 'none'; // 기업명 입력길이
            this.errorKeywordLength.style.display = 'none'; // 기술키워드 입력길이

            result = { countries, techIpcs, keywords };
        }

        return result;
    }

    /**
     * 키워드 추가
     */
    addKeyword() {
        this.setState({
            keywordElementList: [ ...this.state.keywordElementList, '' ]
        });
    }

    /**
     * 키워드 삭제
     */
    removeKeyword(idx) {
        const keywordElementList = JSON.parse(JSON.stringify(this.state.keywordElementList));

        keywordElementList.splice(idx, 1);
        this.setState({
            keywordElementList: keywordElementList
        });
    }
}
