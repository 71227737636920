import React from 'react'
import ContentsHeader from "./ContentsHeader";
import {axiosInstance} from "./Request";
import {getSessionInfo} from "../pages/authentication/authenticationUtil";

export function ConfirmAuthority(Component) {

  class InterceptorComponent extends React.Component {
    constructor(props) {
      super(props);

      this.session = null;
      this.login('tta2021', 'tta2021!');
    }

    render() {
      return (
          this.session && <Component {...this.props} />
      )
    }

    /**
     * 로그인
     */
    login(id, pwd) {
      axiosInstance.post('/v1/access/compa', JSON.stringify({ id, password: pwd }))
          .then(result => {
            getSessionInfo()
                .then(()=>{
                  const sessionStr = sessionStorage.getItem('user_info');
                  const session = sessionStr? JSON.parse(sessionStr): null;

                  this.session = session;
                  this.forceUpdate();
                });
          })
          .finally(()=>{
          });
    }
  }

  return InterceptorComponent

}
