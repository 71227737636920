import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home, Login, SimpleSearch } from './pages';
import { Join, MemberInfo, ChangePassword, Withdrawal, FindPassword } from './pages';
import { CommonWrapper } from './common/CommonWrapper';
import { ConfirmAuthority } from './common/ConfirmAuthority';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/ipservice" component={ConfirmAuthority(CommonWrapper(SimpleSearch))} />
        <Route exact path="/ipservice/simpleSearch" component={ConfirmAuthority(CommonWrapper(SimpleSearch))} />
      </Switch>
    </Router>
  );
};

export default App;
